import Vue from 'vue'
import store from '@/store'
import axios from '@/plugins/axiosV2'
import utils from '@/helpers/utilidades'

const http = axios(process.env.VUE_APP_API_METRICAS);

class Solicitudes {
  obtener_solicitudes(options) {
    return http.get(utils.url_options(`/solicitudes`,options));
  }

  obtener_solicitud(id) {
    return http.get(`/solicitudes?value=${id}`);
  }

  reactivar_solicitud(payload) {
    return http.put(`/solicitudes/reanudar`, payload);
  }

  cancelar_solicitud(payload) {
    return http.put(`/solicitudes/cancelar`, payload);
  }

  agregar_observacion(payload) {
    return http.post(`/solicitudes/observacion`, payload);
  }
}

export default new Solicitudes();