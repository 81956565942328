<template>
  <div>
    <Modal :options="{close:true}" @close="cerrar_modal">
      <div class="title">Observaciones</div>
      <div class="body">
        <div class="tarjetas">
          <template v-if="solicitud.observaciones.length > 0">
          <div v-for="(observacion, index) in solicitud.observaciones" :key="observacion.id" class="card mb-4">
            <div class="card-body">
              <h5 class="card-title">{{ actualizar_numero(index)+' - '+observacion.usuario }}</h5>
              <h6 class="card-subtitle mb-2 text-muted">{{ $moment(observacion.created_at).locale('es').format('ddd DD MMM YYYY HH:mm:ss A') }}</h6>
              <p class="card-text" v-html="observacion.observacion"></p>
            </div>
          </div>
          </template>
          <div v-else>
            No hay observaciones
          </div>
        </div>
        <form @submit.prevent="guardar_observacion">
          <div class="row form-group mt-4">
            <label for="nueva_observacion" class="col-form-label col-sm-2">Observación</label>
            <div class="col-sm-10"><input v-model="observacion.observacion" type="text" name="nueva_observacion" id="nueva_observacion" class="form-control"></div>
          </div>
          <div class="row form-group">
            <div class="col-sm-3 offset-sm-9"><button class="btn secondary-btn">Agregar observación</button></div>
          </div>
        </form>
      </div>
    </Modal>
  </div>
</template>

<script type="text/javascript">
  import Modal from '@/components/Modal'

  import api from '@/apps/metricas/api/solicitudes'

  export default {
    components: {
      Modal
    }
    ,props: {
      solicitud: {
        type:Object
        ,default: () => ({
          observaciones: []
        })
      }
    }
    ,data: function() {
      return {
        total_observaciones: 0
        ,observacion: {
          solicitud_id: 0
          ,usuario: null
          ,observacion: null
          ,tipo: 1
        }
      }
    }
    ,mounted: function() {
      this.solicitud_up = this.solicitud;
    }
    ,methods: {
      guardar_observacion: async function() {
        try {
          this.observacion.solicitud_id = this.solicitud.solicitud_id;
          this.observacion.usuario = this.$auth.getUser().nombre;

          await api.agregar_observacion(this.observacion)

          this.$emit('update');

          this.observacion.observacion = null;
        }catch(e) {
          this.$helper.showAxiosError(e,'Error');
        }
      }
      ,actualizar_numero: function(index) {
        // let index = index + 1;
        let total = this.solicitud.observaciones.length;

        return total - index;
      }
      ,cerrar_modal: function() {
        this.$emit('close');
      }
    }
  }
</script>

<style lang="scss" scoped>
  .body {
    position: relative;

    .tarjetas {
      max-height: 55vh;
      overflow: auto;

      .card {
        min-height: auto !important;
      }

      .card:nth-child(2n+0) {
        background-color: #E6E6E6;
      }
    }
  }
</style>